<script setup lang="ts">
const { t } = useT();

withDefaults(
	defineProps<{
		levels?: number;
		active?: number;
	}>(),
	{
		levels: 8,
		active: 0
	}
);
</script>

<template>
	<div class="levels">
		<div class="level-label">
			<AText :modifiers="['semibold']">
				{{ t("Level") }}
			</AText>

			<div>
				<AText variant="topeka" :modifiers="['extrabold']">
					{{ active }}
				</AText>
				<AText variant="ternopil" :modifiers="['normal']"> /{{ levels }} </AText>
			</div>
		</div>

		<div class="levels-grid">
			<AText
				v-for="level in levels"
				:key="level"
				as="div"
				variant="tlalpan"
				:modifiers="['extrabold']"
				class="level"
				:class="{
					'level--finished': level < active,
					'level--active': level === active
				}"
			>
				{{ level }}
			</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.levels {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	gap: gutter(0.5);
}

.levels-grid {
	width: 100%;
	display: flex;
	gap: gutter(0.125);
}

.level-label {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.level {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	background: var(--celaya);
	color: var(--сirebon);

	&:first-of-type {
		border-radius: 10px 0 0 10px;
	}

	&:last-of-type {
		border-radius: 0 10px 10px 0;
	}

	&--finished,
	&--active {
		color: var(--carabanchel);
	}

	&--finished {
		background: var(--constantine);
	}

	&--active {
		background: var(--chengde);
	}
}
</style>
